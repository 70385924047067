<script>
import { mapGetters } from 'vuex';
import AntInput from '@/components/AntInput.vue';
import { REUSE_POTENTIAL_INDEX } from '@/modules/modules';
import moment from 'moment';
import { getHexColor } from '@/modules/ReusePotentialIndex/utils';
import FileHandlerService from '@/services/file-handler';
import {
  deleteRecord,
  getRecordDocument,
  updateRecord,
} from '@/services/api/record.api';
import { queryTablesV2 } from '@/services/api/v2/connect_v2';

export default {
  name: 'ReusePotentialIndexCrossSections',
  components: { AntInput },
  data: () => {
    return {
      crossSectionLoading: false,
      crossSections: [],
      crossSectionTableId: null,
      newCrossSectionMenu: false,
      newCrossSectionName: '',
      series: [],
      chartOptions: {
        chart: {
          type: 'donut',
        },
        title: {
          text: 'Verhoudingen Doorsnedes',
          style: {
            fontSize: '20px',
          },
        },
        legend: {
          position: 'bottom',
        },
      },
      variant: null,
      variantTableId: null,
    };
  },
  computed: {
    ...mapGetters(['project']),
    variantScore() {
      if (this.crossSections.length > 0) {
        return (
          this.crossSections.reduce(
            (a, k) => a + (parseFloat(k.score) * k.coverage_ratio || 0),
            0
          ) /
          this.crossSections
            .map((s) => s.coverage_ratio)
            .reduce((a, k) => a + k)
        ).toFixed(2);
      }
      return 0;
    },
  },
  watch: {
    variant: {
      deep: true,
      async handler(value, oldValue) {
        if (value !== oldValue) {
          value.dateMenu = false;
          if (value.construction_date) {
            value.construction_date = moment(value.construction_date).format(
              'YYYY-MM-DD'
            );
          }

          if (value.image) {
            value.image = await getRecordDocument(
              value.image?.id,
              this.project.id,
              this.variantTableId
            );
          }
        }
      },
    },
    crossSections: {
      deep: true,
      async handler(value) {
        for (const k of this.crossSections) {
          if (k.image && !k.image?.file && !k.image.downloaded) {
            k.image = await getRecordDocument(
              k.image?.id,
              this.project.id,
              this.crossSectionTableId
            );
            k.image.downloaded = true;
          }
        }
      },
    },
  },
  async mounted() {
    await this.fetchCrossSections();
  },
  methods: {
    getHexColor,
    async fetchCrossSections() {
      this.crossSectionLoading = true;
      try {
        let { crossSections, variants } = await queryTablesV2({
          tables: [
            {
              name: 'CFFA_RPI_VARIANTS',
              project: this.project.id,
              as: 'variants',
              limit: 1,
              records: [this.$route.params.variant],
              columns: [
                {
                  name: 'name',
                },
                {
                  name: 'contact',
                },
                {
                  name: 'image',
                },
                {
                  name: 'construction_date',
                },
                {
                  name: 'date',
                },
              ],
            },
            {
              name: 'CFFA_RPI_CROSS_SECTIONS',
              project: this.project.id,
              as: 'crossSections',
              sortBy: 'title',
              columns: [
                {
                  name: 'variant',
                  conditions: [
                    {
                      operator: '=',
                      value: this.$route.params.variant,
                    },
                  ],
                },
                {
                  name: 'title',
                },
                {
                  name: 'image',
                },
                {
                  name: 'coverage_ratio',
                },
                {
                  name: 'score',
                },
                {
                  name: 'score_revision_time',
                },
              ],
            },
          ],
        });
        this.crossSections = crossSections.records;
        this.crossSectionTableId = crossSections.id;

        this.variant = variants.records[0] ?? null;
        this.variantTableId = variants.id;
        this.setSeriesData();
      } catch (e) {
      } finally {
        this.crossSectionLoading = false;
      }
    },
    setSeriesData() {
      this.series = this.crossSections.map((cs) => cs.coverage_ratio ?? 0);
      this.chartOptions = {
        ...this.chartOptions,
        labels: this.crossSections.map((cs) => cs.title),
      };
    },
    async saveCrossSection() {
      this.crossSectionLoading = true;
      try {
        let crossSection = await createRecord({
          project: { id: this.project.id },
          table: { id: this.crossSectionTableId },
          record: {
            variant: this.$route.params.variant,
            title: this.newCrossSectionName,
          },
        });
        this.crossSections.push(crossSection);
        this.newCrossSectionName = '';
        this.newCrossSectionMenu = false;
        this.setSeriesData();
      } catch (e) {
      } finally {
        this.crossSectionLoading = false;
      }
    },

    async updateCrossSection(cs, key) {
      this.crossSectionLoading = true;
      try {
        let updatedCrossSection = await updateRecord(cs.id, {
          project: { id: this.project.id },
          table: { id: this.crossSectionTableId },
          record: {
            [key]: cs[key],
          },
        });
        if (key !== 'image') {
          let obj = this.crossSections.find((x) => x.id === cs.id);
          Object.assign(obj, { ...obj, [key]: updatedCrossSection[key] });
        }

        cs.coverageMenu = false;
        cs.titleMenu = false;
        this.setSeriesData();
      } catch (e) {
      } finally {
        this.crossSectionLoading = false;
      }
    },

    async updateVariant(key) {
      try {
        let updatedVariant = await updateRecord(this.variant.id, {
          project: { id: this.project.id },
          table: { id: this.variantTableId },
          record: { [key]: this.variant[key] },
        });
        if (key !== 'image') {
          Object.assign(this.variant, {
            ...this.variant,
            [key]: updatedVariant[key],
          });
        }
      } catch (e) {
      } finally {
        this.crossSectionLoading = false;
      }
    },
    navigateToCrossSectionCalculation(event, cs) {
      if (event.target.localName === 'input') return;
      this.$router.push({
        name: `${REUSE_POTENTIAL_INDEX}-calculation`,
        params: {
          variant: cs.variant,
          crossSection: cs.id,
        },
      });
    },
    triggerVariantImageInput() {
      this.$refs.variantImageInput.click();
    },
    async handleVariantImageUpload(event) {
      const file = event.target.files[0];
      this.variant.image = await FileHandlerService.handleFile(file);
      await this.updateVariant('image');
    },

    triggerCrossSectionImageInput(cs) {
      this.$refs[`${cs.id}-ImageInput`][0].click();
    },
    async handleCrossSectionImageUpload(event, cs) {
      const file = event.target.files[0];
      cs.image = await FileHandlerService.handleFile(file);
      await this.updateCrossSection(cs, 'image');
    },
    async deleteCrossSection(cs) {
      await deleteRecord(cs.id, {
        project: {
          id: this.project.id,
        },
        table: {
          id: this.crossSectionTableId,
        },
      });
      this.crossSections = this.crossSections.filter((x) => x.id !== cs.id);
    },
  },
};
</script>

<template>
  <div class="px-5 d-flex">
    <div
      v-if="variant"
      class="background-white flex-grow-1 pa-5 radius-8 ant-border mr-5 d-flex flex-column"
      style="width: 400px"
    >
      <v-subheader class="subheader">RPI Total</v-subheader>
      <div class="d-flex align-center justify-center mt-15">
        <v-slider
          :color="getHexColor(variantScore)"
          :max="100"
          :min="0"
          :step="1"
          :thumb-color="getHexColor(variantScore)"
          :track-fill-color="getHexColor(variantScore)"
          :value="variantScore * 100"
          class="full-width"
          hide-details
          readonly
          thumb-label="always"
          thumb-size="50"
          track-color="#d3d3d3"
        >
          <template #thumb-label="{ value }">
            <span class="black--text">{{ value / 100 }}</span>
          </template>
        </v-slider>
      </div>

      <ant-input label="Variant">
        <template #input-field>
          <v-text-field
            v-model="variant.name"
            dense
            filled
            hide-details
            prepend-inner-icon="mdi-office-building"
            @change="updateVariant('name')"
          >
          </v-text-field>
        </template>
      </ant-input>
      <ant-input label="Contact">
        <template #input-field>
          <v-text-field
            v-model="variant.contact"
            dense
            filled
            hide-details
            prepend-inner-icon="mdi-account-card"
            @change="updateVariant('contact')"
          >
          </v-text-field>
        </template>
      </ant-input>
      <v-menu
        v-model="variant.dateMenu"
        :nudge-right="40"
        min-width="auto"
        offset-y
        transition="scale-transition"
      >
        <template #activator="{ on, attrs }">
          <ant-input label="Bouw datum">
            <template #input-field>
              <v-text-field
                v-model="variant.construction_date"
                dense
                filled
                hide-details
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
          </ant-input>
        </template>
        <v-date-picker
          v-model="variant.construction_date"
          @input="updateVariant('construction_date')"
        ></v-date-picker>
      </v-menu>
      <v-img
        :src="`data:${variant?.image?.mimetype};base64, ${variant?.image?.file}`"
        :style="{ background: variant?.image ? '' : 'lightgrey' }"
        class="my-5 radius-8 img-container"
        contain
        max-height="300px"
        max-width="100%"
        style="position: relative"
      >
        <v-btn
          class="edit-image"
          color="primary"
          fab
          small
          style="position: absolute; right: 10px; top: 10px"
          @click="triggerVariantImageInput"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <input
          ref="variantImageInput"
          accept="image/*"
          style="display: none"
          type="file"
          @change="handleVariantImageUpload"
        />
      </v-img>
      <div id="chart">
        <apexchart
          :options="chartOptions"
          :series="series"
          :width="300"
          type="donut"
        ></apexchart>
      </div>
    </div>

    <div class="grid pr-3">
      <v-card
        v-for="cs in crossSections"
        :key="cs.id"
        :ref="cs.id"
        class="cs-card d-flex flex-column pa-2"
        @click="navigateToCrossSectionCalculation($event, cs)"
      >
        <v-card-title class="subheader"
          >{{ cs.title }}
          <v-spacer />
          <v-menu v-model="cs.titleMenu" :close-on-content-click="false">
            <template #activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <ant-input label="Titel">
                  <template #input-field>
                    <v-text-field
                      v-model="cs.title"
                      autofocus
                      filled
                      hide-details
                      single-line
                      @keydown.enter="updateCrossSection(cs, 'title')"
                    />
                  </template>
                </ant-input>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" small>Opslaan</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <v-menu v-model="cs.deleteMenu" :close-on-content-click="false">
            <template #activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title
                >Weet je zeker dat je deze doorsnede wilt verwijderen?
              </v-card-title>
              <v-card-subtitle
                >Alle resultaten, onderdelen en verbindingen zullen verloren
                gaan
              </v-card-subtitle>
              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" small @click="deleteCrossSection(cs)"
                  >Verwijderen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-card-title>
        <div class="flex-grow-1">
          <v-slider
            :color="getHexColor(cs.score)"
            :max="100"
            :min="0"
            :step="1"
            :thumb-color="getHexColor(cs.score)"
            :track-fill-color="getHexColor(cs.score)"
            :value="cs.score * 100"
            class="full-width mt-5"
            hide-details
            readonly
            thumb-label="always"
            thumb-size="30"
            track-color="#d3d3d3"
          >
            <template #thumb-label="{ value }">
              <span class="black--text">{{ value / 100 }}</span>
            </template>
          </v-slider>
          <v-img
            :src="`data:${cs?.image?.mimetype};base64, ${cs?.image?.file}`"
            :style="{ background: cs?.image ? '' : 'lightgrey' }"
            class="my-5 radius-8 img-container"
            contain
            height="100%"
            max-height="200"
            max-width="500"
            style="position: relative"
          >
            <v-btn
              class="edit-image"
              color="primary"
              fab
              small
              style="position: absolute; right: 10px; top: 10px"
              @click.stop="triggerCrossSectionImageInput(cs)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <input
              :ref="`${cs.id}-ImageInput`"
              accept="image/*"
              style="display: none"
              type="file"
              @change="handleCrossSectionImageUpload($event, cs)"
            />
          </v-img>
        </div>

        <v-card-actions>
          <span class="mr-2">Dekkingsgraad</span>

          <v-menu
            v-model="cs.coverageMenu"
            :close-on-content-click="false"
            absolute
          >
            <template #activator="{ on, attrs }">
              <v-chip small v-bind="attrs" v-on="on"
                >{{ cs.coverage_ratio ?? '-' }}
              </v-chip>
            </template>
            <v-card>
              <v-card-text>
                <ant-input label="Dekkingsgraad">
                  <template #input-field>
                    <v-text-field
                      v-model="cs.coverage_ratio"
                      :min="0"
                      autofocus
                      filled
                      hide-details
                      single-line
                      type="number"
                      @keydown.enter="updateCrossSection(cs, 'coverage_ratio')"
                    />
                  </template>
                </ant-input>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  small
                  @click="updateCrossSection(cs, 'coverage_ratio')"
                  >opslaan
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <v-spacer />
          <v-btn
            color="primary"
            elevation="0"
            small
            @click="navigateToCrossSectionCalculation(cs)"
            >Berekening
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <v-menu v-model="newCrossSectionMenu" absolute>
      <template #activator="{ on, attrs }">
        <div
          :style="{
            'pointer-events': crossSectionTableId ? 'auto' : 'none',
            opacity: crossSectionTableId ? 1 : 0.5,
          }"
          class="new-item-card"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon x-large> mdi-plus</v-icon>
        </div>
      </template>
      <v-card>
        <v-card-text>
          <ant-input label="Titel">
            <template #input-field>
              <v-text-field
                v-model="newCrossSectionName"
                autofocus
                filled
                hide-details
                single-line
                @keydown.enter="saveCrossSection"
              />
            </template>
          </ant-input>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" elevation="0" small @click="saveCrossSection"
            >Opslaan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>
<style lang="scss" scoped>
.grid {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  overflow-y: auto;
}

.new-item-card {
  margin: 10px 10px;
  min-width: 200px;
  border: dashed 2px grey;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 200ms;
  cursor: pointer;

  &:hover {
    border: dashed 2px var(--v-primary-base);
    background-color: rgba(0, 0, 0, 0.05);

    .v-icon {
      color: var(--v-primary-base);
    }
  }
}

.cs-card {
  height: 400px;
  width: 500px;
}

.subheader {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  color: var(--v-primary-base);
  font-size: 20px;
}

.subheader-small {
  display: flex;
  align-items: center;
  color: var(--v-primary-base);
}

.img-container {
  .edit-image {
    opacity: 0;
  }

  &:hover {
    .edit-image {
      opacity: 1;
    }
  }
}
</style>
