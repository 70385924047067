<script>
import { getHexColor } from '@/modules/ReusePotentialIndex/utils';

export default {
  name: 'ReusePotentialIndexDetailsDialog',
  props: {
    crossSection: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      detailsDialog: false,
    };
  },
  methods: {
    getHexColor,
    getComponentScore(item) {
      let score = 0;
      let count = 0;
      if (item.functionalDisconnectionScore !== -1) {
        score += item.functionalDisconnectionScore * 2;
        count += 2;
      }

      if (item.productLevelScore !== -1) {
        score += item.productLevelScore * 2;
        count += 2;
      }

      if (item.componentConnectionScore !== -1) {
        score += item.componentConnectionScore * 2;
        count += 2;
      }

      if (item.lifespanScore !== -1) {
        score += item.lifespanScore * 1;
        count += 1;
      }

      if (item.connectionTypeScore !== -1) {
        score += item.connectionTypeScore * 3;
        count += 3;
      }

      if (item.connectionDamageScore !== -1) {
        score += item.connectionDamageScore * 3;
        count += 3;
      }

      return parseFloat((score / count).toFixed(2));
    },
  },
};
</script>

<template>
  <v-dialog v-model="detailsDialog" width="90%">
    <template #activator="{ on, attrs }">
      <v-btn
        class="mt-1"
        color="primary"
        elevation="0"
        small
        v-bind="attrs"
        v-on="on"
        >Details
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 text-primary">
        Beoordeling per onderdeel voor doorsnede {{ crossSection.name }}
      </v-card-title>
      <v-divider />

      <v-card-text>
        <v-data-table
          :headers="[
            { text: 'Component', value: 'name' },
            {
              text: 'Functionele Ontkoppeling (2)',
              value: 'functionalDisconnectionScore',
            },
            { text: 'Productniveaus (2)', value: 'productLevelScore' },
            {
              text: 'Aantal Relaties (2)',
              value: 'componentConnectionScore',
              align: 'center',
            },
            { text: 'Levensduur (2)', value: 'lifespanScore', align: 'center' },
            {
              text: 'Type Verbindingen (3)',
              value: 'connectionTypeScore',
              align: 'center',
            },
            {
              text: 'Verbinding Schade (3)',
              value: 'connectionDamageScore',
              align: 'center',
            },
            {
              text: '',
              value: 'space',
            },
            {
              text: 'Totaal',
              value: 'total',
              align: 'center',
            },
          ]"
          :items="crossSection.components"
          :items-per-page="-1"
          dense
          hide-default-footer
        >
          <template #item.name="{ item }">
            <td>{{ item.nr }}. {{ item.name }}</td>
          </template>
          <template
            v-for="key in [
              'functionalDisconnectionScore',
              'productLevelScore',
              'lifespanScore',
              'componentConnectionScore',
              'connectionTypeScore',
              'connectionDamageScore',
            ]"
            #[`item.${key}`]="{ item, value }"
          >
            <td
              :key="`${item.id}-${key}`"
              :style="{
                'background-color': getHexColor(value),
              }"
              class="text-center"
            >
              {{ value >= 0 ? value : '-' }}
            </td>
          </template>
          <template #item.total="{ item }">
            <td
              :style="{
                'background-color': getHexColor(getComponentScore(item)),
              }"
              class="text-center ant-border-left-darker"
            >
              {{ getComponentScore(item) }}
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped></style>
