<script>
import { mapGetters } from 'vuex';
import {
  connectionsByComponentsQuery,
  crossSectionComponentsQuery,
  variantsAndCrossSectionsQuery,
} from '@/modules/ReusePotentialIndex/queries';
import {
  calculateComponentConnections,
  calculateConnectionDamages,
  calculateConnectionTypes,
  calculateFunctionalDisconnection,
  calculateLifespanLeft,
  calculateProductLevels,
  calculateTypeClustering,
} from '@/modules/ReusePotentialIndex/calculations';
import AntInput from '@/components/AntInput.vue';
import { getHexColor } from '@/modules/ReusePotentialIndex/utils';
import moment from 'moment';
import ReusePotentialIndexDetailsDialog from '@/modules/ReusePotentialIndex/ReusePotentialIndexDetailsDialog.vue';
import { getRecordDocument, updateRecord } from '@/services/api/record.api';
import { queryTablesV2 } from '@/services/api/v2/connect_v2';

export default {
  name: 'ReusePotentialIndexResult',
  components: { ReusePotentialIndexDetailsDialog, AntInput },
  data: () => {
    return {
      series: [],
      chartOptions: {
        chart: {
          width: 700,
          height: 500,
          type: 'radar',
        },
        dataLabels: {
          enabled: true,
        },
        plotOptions: {
          radar: {
            size: 200,
            polygons: {
              strokeColors: '#e9e9e9',
              fill: {
                colors: ['#f8f8f8', '#fff'],
              },
            },
          },
        },
        colors: ['#FF4560'],
        xaxis: {
          categories: [
            'Functionele ontkoppeling',
            'Aantal productniveaus',
            'Type clustering',
            'Aantal relaties',
            'Levensduur',
            'Type verbindingen',
            'Schade aan verbinding',
          ],
        },
        yaxis: {
          forceNiceScale: true,
          stepSize: 1,
          min: 0,
          max: 1,
          seriesName: "Doorsnede's",
          labels: {
            formatter: function (value) {
              return parseFloat(value.toFixed(2));
            },
          },
        },
      },
      variant: null,
      components: [],
      connections: [],
      crossSections: [],
      focusedCrossSection: null,
      crossSectionScores: [],
      revisionLoading: false,
      crossSectionsTableId: '',
      isLoading: false,
      tipsIndex: 0,
    };
  },
  computed: {
    ...mapGetters(['project']),

    totalScore() {
      if (this.focusedCrossSection) {
        return this.focusedCrossSection.total;
      }
      return parseFloat(
        (
          (this.functionalDisconnectionScore * 2 +
            this.assemblyLevelsScore * 2 +
            this.typeClusteringScore * 2 +
            this.connectionCountScore * 2 +
            this.lifespanScore +
            this.connectionTypeScore * 3 +
            this.connectionDamagesScore * 3) /
          15
        ).toFixed(2)
      );
    },

    overallScore() {
      return parseFloat(
        (
          (this.functionalDisconnectionScore * 2 +
            this.assemblyLevelsScore * 2 +
            this.typeClusteringScore * 2 +
            this.connectionCountScore * 2 +
            this.lifespanScore +
            this.connectionTypeScore * 3 +
            this.connectionDamagesScore * 3) /
          15
        ).toFixed(2)
      );
    },

    functionalDisconnectionScore() {
      if (this.focusedCrossSection) {
        return this.focusedCrossSection.data[0];
      }
      if (this.crossSectionScores.length > 0) {
        return parseFloat(
          (
            this.crossSectionScores.reduce(
              (total, item) => total + item.data[0] * item.coverage_ratio,
              0
            ) /
            this.crossSectionScores
              .map((s) => s.coverage_ratio)
              .reduce((a, k) => a + k)
          ).toFixed(2)
        );
      }
      return 0;
    },
    assemblyLevelsScore() {
      if (this.focusedCrossSection) {
        return this.focusedCrossSection.data[1];
      }
      if (this.crossSectionScores.length > 0) {
        return parseFloat(
          (
            this.crossSectionScores.reduce(
              (total, item) => total + item.data[1] * item.coverage_ratio,
              0
            ) /
            this.crossSectionScores
              .map((s) => s.coverage_ratio)
              .reduce((a, k) => a + k)
          ).toFixed(2)
        );
      }
      return 0;
    },
    typeClusteringScore() {
      if (this.focusedCrossSection) {
        return this.focusedCrossSection.data[2];
      }
      if (this.crossSectionScores.length > 0) {
        return parseFloat(
          (
            this.crossSectionScores.reduce(
              (total, item) => total + item.data[2] * item.coverage_ratio,
              0
            ) /
            this.crossSectionScores
              .map((s) => s.coverage_ratio)
              .reduce((a, k) => a + k)
          ).toFixed(2)
        );
      }
      return 0;
    },
    connectionCountScore() {
      if (this.focusedCrossSection) {
        return this.focusedCrossSection.data[3];
      }
      if (this.crossSectionScores.length > 0) {
        return parseFloat(
          (
            this.crossSectionScores.reduce(
              (total, item) => total + item.data[3] * item.coverage_ratio,
              0
            ) /
            this.crossSectionScores
              .map((s) => s.coverage_ratio)
              .reduce((a, k) => a + k)
          ).toFixed(2)
        );
      }
      return 0;
    },
    lifespanScore() {
      if (this.focusedCrossSection) {
        return this.focusedCrossSection.data[4];
      }
      if (this.crossSectionScores.length > 0) {
        return parseFloat(
          (
            this.crossSectionScores.reduce(
              (total, item) => total + item.data[4] * item.coverage_ratio,
              0
            ) /
            this.crossSectionScores
              .map((s) => s.coverage_ratio)
              .reduce((a, k) => a + k)
          ).toFixed(2)
        );
      }
      return 0;
    },
    connectionTypeScore() {
      if (this.focusedCrossSection) {
        return this.focusedCrossSection.data[5];
      }
      if (this.crossSectionScores.length > 0) {
        return parseFloat(
          (
            this.crossSectionScores.reduce(
              (total, item) => total + item.data[5] * item.coverage_ratio,
              0
            ) /
            this.crossSectionScores
              .map((s) => s.coverage_ratio)
              .reduce((a, k) => a + k)
          ).toFixed(2)
        );
      }
      return 0;
    },
    connectionDamagesScore() {
      if (this.focusedCrossSection) {
        return this.focusedCrossSection.data[6];
      }
      if (this.crossSectionScores.length > 0) {
        return parseFloat(
          (
            this.crossSectionScores.reduce(
              (total, item) => total + item.data[6] * item.coverage_ratio,
              0
            ) /
            this.crossSectionScores
              .map((s) => s.coverage_ratio)
              .reduce((a, k) => a + k)
          ).toFixed(2)
        );
      }
      return 0;
    },
  },
  watch: {
    async focusedCrossSection(value) {
      if (value) {
        this.series = [
          this.series.find((x) => x.name === 'Totaal'),
          this.crossSectionScores.find((x) => x.name === value.name),
        ];

        if (value?.image) {
          value.image = await getRecordDocument(
            value.image?.id,
            this.project.id,
            this.crossSectionsTableId
          );
        }

        this.chartOptions = {
          ...this.chartOptions,
          title: {
            text: `RPI ${this.focusedCrossSection.name} ${this.focusedCrossSection.total}`,
            align: 'center',
            style: {
              color: this.$vuetify.theme.themes.light.primary.toString(),
              fontSize: '20px',
            },
          },
          colors: this.series.map((s) => s?.color),
        };
      } else {
        this.series = [
          ...this.crossSectionScores,
          ...[
            {
              name: 'Totaal',
              color: '#0000FF',
              data: [
                this.functionalDisconnectionScore,
                this.assemblyLevelsScore,
                this.typeClusteringScore,
                this.connectionCountScore,
                this.lifespanScore,
                this.connectionTypeScore,
                this.connectionDamagesScore,
              ],
            },
          ],
        ];
        this.chartOptions = {
          ...this.chartOptions,
          title: {
            text: `RPI totaal ${this.totalScore}`,
            align: 'center',
            style: {
              color: this.$vuetify.theme.themes.light.primary.toString(),
              fontSize: '20px',
            },
          },
          colors: this.series.map((s) => s.color),
        };
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.fetchData();
    this.isLoading = false;
  },
  methods: {
    getHexColor,
    async setChartData() {
      for (const cs of this.crossSections) {
        let { components } = await queryTablesV2(
          crossSectionComponentsQuery(this.project.id, cs.id)
        );

        const componentRecords = components.records;

        let { connections } = await queryTablesV2(
          connectionsByComponentsQuery(
            this.project.id,
            componentRecords.map((x) => x.id)
          )
        );

        const connectionRecords = connections.records;

        const obj = {
          id: cs.id,
          name: cs.title,
          score: cs.score,
          coverage_ratio: cs.coverage_ratio,
          image: cs.image,
          components: componentRecords,
          connections: connectionRecords,
          data: [
            calculateFunctionalDisconnection(componentRecords),
            calculateProductLevels(componentRecords),
            calculateTypeClustering(componentRecords),
            calculateComponentConnections(componentRecords, connectionRecords),
            calculateLifespanLeft(
              this.variant.construction_date,
              componentRecords
            ),
            calculateConnectionTypes(componentRecords, connectionRecords),
            calculateConnectionDamages(componentRecords, connectionRecords),
          ],
        };

        obj.total = parseFloat(
          (
            (obj.data[0] * 2 +
              obj.data[1] * 2 +
              obj.data[2] * 2 +
              obj.data[3] * 2 +
              obj.data[4] +
              obj.data[5] * 3 +
              obj.data[6] * 3) /
            15
          ).toFixed(2)
        );

        obj.color = getHexColor(obj.total);

        this.crossSectionScores.push(obj);
      }

      this.series = [
        ...this.crossSectionScores,
        ...[
          {
            name: 'Totaal',
            color: '#0000FF',
            data: [
              this.functionalDisconnectionScore,
              this.assemblyLevelsScore,
              this.typeClusteringScore,
              this.connectionCountScore,
              this.lifespanScore,
              this.connectionTypeScore,
              this.connectionDamagesScore,
            ],
          },
        ],
      ];
      this.chartOptions = {
        ...this.chartOptions,
        title: {
          text: `RPI totaal ${this.totalScore}`,
          align: 'center',
          style: {
            color: this.$vuetify.theme.themes.light.primary.toString(),
            fontSize: '20px',
          },
        },
        colors: this.series.map((s) => s.color),
      };
    },
    async fetchData() {
      try {
        let { variants, crossSections } = await queryTablesV2(
          variantsAndCrossSectionsQuery(
            this.project.id,
            this.$route.params.variant
          )
        );
        this.variant = variants.records[0] ?? null;
        if (this.variant?.image) {
          this.variant.image = await getRecordDocument(
            variants.id,
            this.project.id,
            this.variant.image?.id
          );
        }
        this.crossSections = crossSections.records;
        this.crossSectionsTableId = crossSections.id;
      } catch (e) {
        console.log(e);
      } finally {
        await this.setChartData();
      }
    },
    async makeScoreRevision() {
      this.revisionLoading = true;
      try {
        for (const cs of this.crossSectionScores) {
          await updateRecord(cs.id, {
            project: { id: this.project.id },
            table: { id: this.crossSectionsTableId },
            record: {
              score: cs.total,
              score_revision_time: moment().unix(),
            },
          });
        }
      } catch (e) {
      } finally {
        this.revisionLoading = false;
      }
    },
  },
};
</script>

<template>
  <div class="d-flex flex-column">
    <v-progress-linear
      v-show="isLoading"
      color="primary"
      height="5"
      indeterminate
    />
    <div
      :class="{ loading: isLoading }"
      class="d-flex px-5 flex-column flex-grow-1"
    >
      <div class="d-flex flex-grow-1 mb-5">
        <div
          class="d-flex justify-center flex-grow-1 align-center"
          style="position: relative"
        >
          <apexchart
            :options="chartOptions"
            :series="series"
            style="z-index: 2"
            type="radar"
          ></apexchart>

          <v-menu offset-y top>
            <template #activator="{ on: menu, attrs }">
              <v-tooltip right>
                <template #activator="{ on: tooltip }">
                  <v-btn
                    color="primary"
                    fab
                    small
                    style="
                      position: absolute;
                      left: 0;
                      bottom: 60px;
                      z-index: 10;
                    "
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                  >
                    <v-icon>mdi-clipboard-list</v-icon>
                  </v-btn>
                </template>
                <span>Legenda</span>
              </v-tooltip>
            </template>
            <v-card>
              <v-img
                contain
                height="250"
                max-height="250"
                position="left"
                src="./assets/result_graph.png"
                width="300"
              />
            </v-card>
          </v-menu>
        </div>

        <div class="d-flex flex-column mx-5 fs-12">
          <div
            :class="{
              'inactive-score-card': totalScore < 0.75,
            }"
            :style="{ background: getHexColor(0.8) }"
            class="flex-grow-1 pa-2 radius-4"
          >
            <span
              >Reversible Building<br /><br />
              Circularity Profile 4<br />
              More than 70% Reuse 30%<br />
              Up to 30% recycling</span
            >
          </div>
          <div
            :class="{
              'inactive-score-card': totalScore > 0.75 || totalScore < 0.55,
            }"
            :style="{ background: getHexColor(0.6) }"
            class="flex-grow-1 pa-2 mb-2 radius-4"
          >
            <span
              >Reversible Building<br /><br />
              Circularity Profile 3<br />
              40% to 70% Reuse<br />
              30% to 60% recycling</span
            >
          </div>
          <div
            :class="{
              'inactive-score-card': totalScore > 0.55 || totalScore < 0.35,
            }"
            :style="{ background: getHexColor(0.4) }"
            class="flex-grow-1 pa-2 mb-2 radius-4"
          >
            <span
              >Partly Reversible<br /><br />
              Circularity Profile 2<br />
              20% to 40% Reuse<br />
              40% to 80% recycling</span
            >
          </div>
          <div
            :class="{
              'inactive-score-card': totalScore > 0.35 || totalScore < 0.25,
            }"
            :style="{ background: getHexColor(0.2) }"
            class="flex-grow-1 pa-2 mb-2 radius-4"
          >
            <span
              >Partly Reversible<br /><br />
              Circularity Profile 1<br />
              up to 20% Reuse<br />
              more than 80% recycling</span
            >
          </div>
          <div
            :class="{ 'inactive-score-card': totalScore > 0.25 }"
            :style="{ background: getHexColor(0) }"
            class="flex-grow-1 pa-2 mb-2 radius-4"
          >
            <span
              >Irrevesible<br />
              Building<br />
              <br />
              Circularity Profile 0</span
            >
          </div>
        </div>

        <v-card
          class="mr-5 d-flex flex-column"
          elevation="0"
          outlined
          style="width: 500px; max-width: 500px"
        >
          <v-card-title class="subheader"
            >RPI
            {{ focusedCrossSection ? focusedCrossSection.name : 'Totaal' }}
          </v-card-title>
          <v-card-text class="pa-10 pt-15 flex-grow-1 d-flex flex-column">
            <v-slider
              :color="getHexColor(totalScore)"
              :max="100"
              :min="0"
              :step="1"
              :thumb-color="getHexColor(totalScore)"
              :track-fill-color="getHexColor(totalScore)"
              :value="totalScore * 100"
              class="full-width flex-grow-0"
              readonly
              thumb-label="always"
              thumb-size="50"
              track-color="#d3d3d3"
            >
              <template #thumb-label="{ value }">
                <span class="black--text">{{ value / 100 }}</span>
              </template>
            </v-slider>
            <v-subheader class="subheader-small px-0">Doorsnedes</v-subheader>
            <v-list
              class="flex-grow-1 overflow-y-auto flex-scroll-height"
              style="max-height: 240px"
            >
              <v-list-item-group color="primary" mandatory>
                <v-list-item two-line @click="focusedCrossSection = null">
                  <v-list-item-content>
                    <v-list-item-title class="d-flex align-center"
                      >Totaal
                      <v-spacer />
                      <v-avatar
                        :color="getHexColor(overallScore)"
                        size="30"
                        style="font-size: 12px"
                        >{{ overallScore }}
                      </v-avatar>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-for="item in crossSectionScores"
                  :key="item.id"
                  two-line
                  @click="focusedCrossSection = item"
                >
                  <v-list-item-content>
                    <v-list-item-title class="d-flex align-center"
                      >{{ item.name }}
                      <v-spacer />
                      <v-avatar
                        :color="getHexColor(item.total)"
                        size="30"
                        style="font-size: 12px"
                        >{{ item.total }}
                      </v-avatar>
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="item.total !== item.score">
                      <v-icon class="mr-1" color="warning" small
                        >mdi-alert
                      </v-icon>
                      Score ({{ item.total ?? '-' }}) is niet gelijk aan laatste
                      versie ({{ item.score ?? '-' }})
                    </v-list-item-subtitle>
                    <div class="d-flex align-center">
                      <span class="mr-2">Dekkingsgraad</span>
                      <v-chip small>{{ item.coverage_ratio ?? '-' }}</v-chip>
                      <v-spacer />
                      <reuse-potential-index-details-dialog
                        :cross-section="item"
                      />
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :disabled="revisionLoading"
              :loading="revisionLoading"
              class="full-width"
              color="primary"
              small
              @click="makeScoreRevision"
              >Versie opslaan
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card
          class="d-flex flex-column"
          elevation="0"
          outlined
          style="width: 450px"
        >
          <v-card-title class="subheader">Samenvatting</v-card-title>
          <v-card-text class="d-flex pa-5 flex-column flex-grow-1">
            <div class="d-flex mb-15 mt-5">
              <div @click="tipsIndex = 0">
                <v-slider
                  :color="getHexColor(functionalDisconnectionScore)"
                  :max="100"
                  :min="0"
                  :step="1"
                  :thumb-color="getHexColor(functionalDisconnectionScore)"
                  :track-fill-color="getHexColor(functionalDisconnectionScore)"
                  :value="functionalDisconnectionScore * 100"
                  class="full-width"
                  hide-details
                  inverse-label
                  readonly
                  style="width: 200px"
                  thumb-label="always"
                  thumb-size="40"
                  track-color="#d3d3d3"
                >
                  <template #thumb-label="{ value }">
                    <span class="black--text">{{ value / 100 }}</span>
                  </template>
                </v-slider>
                <span>1. Functionele ontkoppeling</span>
              </div>
              <v-spacer />
              <div @click="tipsIndex = 1">
                <v-slider
                  :color="getHexColor(assemblyLevelsScore)"
                  :max="100"
                  :min="0"
                  :step="1"
                  :thumb-color="getHexColor(assemblyLevelsScore)"
                  :track-fill-color="getHexColor(assemblyLevelsScore)"
                  :value="assemblyLevelsScore * 100"
                  class="full-width"
                  hide-details
                  inverse-label
                  readonly
                  style="width: 200px"
                  thumb-label="always"
                  thumb-size="40"
                  track-color="#d3d3d3"
                >
                  <template #thumb-label="{ value }">
                    <span class="black--text">{{ value / 100 }}</span>
                  </template>
                </v-slider>
                <span>2. Productniveaus</span>
              </div>
            </div>
            <div class="d-flex mb-15">
              <div @click="tipsIndex = 2">
                <v-slider
                  :color="getHexColor(typeClusteringScore)"
                  :max="100"
                  :min="0"
                  :step="1"
                  :thumb-color="getHexColor(typeClusteringScore)"
                  :track-fill-color="getHexColor(typeClusteringScore)"
                  :value="typeClusteringScore * 100"
                  class="full-width"
                  hide-details
                  inverse-label
                  readonly
                  style="width: 200px"
                  thumb-label="always"
                  thumb-size="40"
                  track-color="#d3d3d3"
                >
                  <template #thumb-label="{ value }">
                    <span class="black--text">{{ value / 100 }}</span>
                  </template>
                </v-slider>
                <span>3. Type clustering</span>
              </div>
              <v-spacer />
              <div @click="tipsIndex = 3">
                <v-slider
                  :color="getHexColor(connectionCountScore)"
                  :max="100"
                  :min="0"
                  :step="1"
                  :thumb-color="getHexColor(connectionCountScore)"
                  :track-fill-color="getHexColor(connectionCountScore)"
                  :value="connectionCountScore * 100"
                  class="full-width"
                  hide-details
                  inverse-label
                  readonly
                  style="width: 200px"
                  thumb-label="always"
                  thumb-size="40"
                  track-color="#d3d3d3"
                >
                  <template #thumb-label="{ value }">
                    <span class="black--text">{{ value / 100 }}</span>
                  </template>
                </v-slider>
                <span>4. Aantal relaties</span>
              </div>
            </div>
            <div class="d-flex mb-15">
              <div @click="tipsIndex = 4">
                <v-slider
                  :color="getHexColor(lifespanScore)"
                  :max="100"
                  :min="0"
                  :step="1"
                  :thumb-color="getHexColor(lifespanScore)"
                  :track-fill-color="getHexColor(lifespanScore)"
                  :value="lifespanScore * 100"
                  class="full-width"
                  hide-details
                  inverse-label
                  readonly
                  style="width: 200px"
                  thumb-label="always"
                  thumb-size="40"
                  track-color="#d3d3d3"
                >
                  <template #thumb-label="{ value }">
                    <span class="black--text">{{ value / 100 }}</span>
                  </template>
                </v-slider>
                <span>5. Levensduur</span>
              </div>
              <v-spacer />
              <div @click="tipsIndex = 5">
                <v-slider
                  :color="getHexColor(connectionTypeScore)"
                  :max="100"
                  :min="0"
                  :step="1"
                  :thumb-color="getHexColor(connectionTypeScore)"
                  :track-fill-color="getHexColor(connectionTypeScore)"
                  :value="connectionTypeScore * 100"
                  class="full-width"
                  hide-details
                  inverse-label
                  readonly
                  style="width: 200px"
                  thumb-label="always"
                  thumb-size="40"
                  track-color="#d3d3d3"
                >
                  <template #thumb-label="{ value }">
                    <span class="black--text">{{ value / 100 }}</span>
                  </template>
                </v-slider>

                <span>6. Type verbindingen</span>
              </div>
            </div>
            <div class="d-flex">
              <div @click="tipsIndex = 6">
                <v-slider
                  :color="getHexColor(connectionDamagesScore)"
                  :max="100"
                  :min="0"
                  :step="1"
                  :thumb-color="getHexColor(connectionDamagesScore)"
                  :track-fill-color="getHexColor(connectionDamagesScore)"
                  :value="connectionDamagesScore * 100"
                  class="full-width"
                  hide-details
                  inverse-label
                  readonly
                  style="width: 200px"
                  thumb-label="always"
                  thumb-size="40"
                  track-color="#d3d3d3"
                >
                  <template #thumb-label="{ value }">
                    <span class="black--text">{{ value / 100 }}</span>
                  </template>
                </v-slider>
                <span>7. Schade verbindingen</span>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
      <div class="d-flex">
        <div
          class="d-flex flex-column flex-1 mr-5 background-white radius-4 overflow-hidden"
        >
          <v-subheader class="subheader">Tips</v-subheader>
          <v-tabs v-model="tipsIndex" class="flex-grow-1" vertical>
            <v-tab class="justify-start"> 1. Functionele ontkoppeling</v-tab>
            <v-tab class="justify-start"> 2. Aantal productniveaus</v-tab>
            <v-tab class="justify-start"> 3. Type clustering</v-tab>
            <v-tab class="justify-start"> 4. Aantal relaties</v-tab>
            <v-tab class="justify-start">
              5. Resterende technische levensduur
            </v-tab>
            <v-tab class="justify-start"> 6. Type verbindingen</v-tab>
            <v-tab class="justify-start"> 7. Schade aan verbinding</v-tab>

            <v-tab-item>
              <v-card flat>
                <v-card-title class="subheader"
                  >Functionele ontkoppeling
                </v-card-title>
                <v-card-text>
                  <p>
                    Zorg er voor dat onderdelen monofunctioneel zijn in plaats
                    van dat ze meerdere functies vervullen. Hierdoor kunnen
                    gebouwen gemakkelijker het specifieke functionele deel
                    aanpassen zonder aantasting van de andere. Uitzondering op
                    deze regel is wanneer onderdelen een generieke functie
                    hebben, als de afwerkingsfunctie integreert met zijn
                    basisfunctie zoals gevel of draagconstructie. Hierdoor wordt
                    de noodzaak om afwerking te gebruiken verminderd. Voorbeeld:
                    als van een houten kolom met dragende functie het hout
                    direct ook de afwerking is van de kolom.
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-title class="subheader"
                  >Aantal productniveaus
                </v-card-title>
                <v-card-text>
                  <p>
                    Vermijdt toepassing van los materiaal en halffabricaat op de
                    bouwplaats (onderdelen die op de bouwplaat verdere bewerking
                    nodig hebben). Maak gebruik van kant-en-klaar componenten en
                    systemen.
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-title class="subheader">Type clustering</v-card-title>
                <v-card-text>
                  <p>
                    Meer prefab-onderdelen en toepassing van geïndustrialiseerde
                    systemen resulteren in een hogere score voor deze indicator.
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-title class="subheader">Aantal relaties</v-card-title>
                <v-card-text>
                  <p>
                    Hoe minder relaties een onderdeel heeft, des te makkelijker
                    het is om het onderdeel te recupereren. Probeer het aantal
                    relaties te minimaliseren. Dit kan ook door toepassing van
                    een intermediair.
                    <br />
                    <br />

                    Bij score <0,3 Probeer minder dan 6 relaties toe te passen.
                    Dit kan ook door toepassing van een intermediair.
                    <br />
                    <br />

                    Bij score <0,6 Probeer minder dan 4 relaties toe te passen.
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-title class="subheader"
                  >Resterende technische levensduur
                </v-card-title>
                <v-card-text>
                  <p>
                    Onderdelen met een levensduur langer dan 20 jaar zullen een
                    hoge score halen. Onderdelen met een levensduur korte dan 10
                    jaar zullen <0,5 scoren.
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-title class="subheader">Type verbindingen</v-card-title>
                <v-card-text>
                  <p>
                    Bij score <0,3 Vermijd chemische verbindingen.
                    <br />
                    <br />
                    Bij score <0,7 Hogere score verkrijgt men door toepassing
                    van een
                    <span class="font-weight-bold">intermediair</span> waarmee
                    twee onderdelen onafhankelijke met elkaar zijn verbonden of
                    interlock verbindingen waardoor twee elementen door hun
                    slimme geometrie met elkaar zijn verbonden.
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-title class="subheader"
                  >Schade aan verbinding
                </v-card-title>
                <v-card-text>
                  <p>
                    Schade aan verbindingen berekent het percentage van een type
                    verbinding t.o.v het totaal aantal verbindingen. Als type
                    verbinding D4 70% van alle verbindingen dekt dan krijgt
                    Schade aan Verbinding een score van 0,1 (grootst schade).
                    <br />
                    <br />
                    Bij score < 0,3 Verminder het % chemische verbindingen.
                    <br />
                    <br />
                    Bij score < 0,6 Verminder het % directe insteekverbindingen
                    (schroef, nagel, rivet) en chemische verbindingen.
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </div>
        <v-card
          v-if="variant?.image && !focusedCrossSection"
          class="mr-5"
          elevation="0"
          outlined
        >
          <v-img
            :src="`data:${variant?.image?.mimetype};base64, ${variant?.image?.file}`"
            contain
            max-height="400px"
            max-width="500px"
            width="500px"
          />
        </v-card>
        <v-card
          v-if="focusedCrossSection?.image"
          class="mr-5"
          elevation="0"
          outlined
        >
          <v-img
            :src="`data:${focusedCrossSection?.image?.mimetype};base64, ${focusedCrossSection?.image?.file}`"
            contain
            max-height="400px"
            max-width="500px"
            width="500px"
          />
        </v-card>

        <v-card elevation="0" outlined style="width: 450px">
          <v-subheader class="subheader">Uitleg van resultaat</v-subheader>
          <v-card-text class="pt-0">
            <span class="font-weight-bold"
              >Totaal Reuse Potential Index geeft aan of gebouwconstructie
              is:</span
            ><br />
            RP Index = 0.7-0.9 (Omkeerbaar/circulair )<br />
            RP Index = 0.6-0.4 (Gedeeltelijke omkeerbaar/circulair)<br />
            RP Index = 0.1-0.3 (Onomkeerbaar/niet circulair)<br />
            <span class="font-weight-bold"
              >Index geeft ook aan hergebruikopties van materialen:</span
            ><br />
            RP Index = 0.7-0.9 (direct hergebruik door kleine reparatie)<br />
            RP Index = 0.6-0.4 (hergebruik door herfabricage)<br />
            RP Index = 0.1-0.3 (van mono-recycling tot afval)<br />

            <v-img contain max-height="170" src="./assets/result_graph.png" />
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.subheader {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  color: var(--v-primary-base);
  font-size: 20px;
}

.subheader-small {
  display: flex;
  align-items: center;
  color: var(--v-primary-base);
}

.loading {
  // add a blur effect
  filter: blur(1px);
  // add a fade effect
  opacity: 0.8;
  // smoothing the transition
  transition: all 0.3s ease-out;

  // select all direct and indirect children
  * {
    filter: blur(2px);
    opacity: 0.7;
    transition: all 0.3s ease-out;
  }
}

.inactive-score-card {
  opacity: 0.4;
  transform: scale(0.9);
}
</style>
